.navbar-section {
    padding: 10px 15px 2px 5px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    width: 100%;
    align-items: center;
    height: 80px;
    background-image: linear-gradient(132deg, #dfe6e8 0%, #00488a 100%);
    text-shadow: 1px 1px 1px rgb(255, 255, 255), 0 0 1em white, 0 0 0.1em black;
    border-bottom: 3px solid #1d83c4;
    border-left: none;
    border-right: none;
    border-top: none;
}

.logo {
    height: 64px;
    size: 50px;
    }

.navbar-items {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    font-family: 'Nunito', sans-serif;
}
.navbar-items > li {
    list-style-type: none;
}

.navbar-links {
    text-decoration: none;
    color: white;
    font-size: 21px;
    letter-spacing: .8px;
    text-shadow: 1px 1px 1px rgb(2, 2, 2), 0 0 1em rgba(151, 155, 139, 0), 0 0 0.1em rgb(39, 37, 37);
}

.navbar-links.active {
    color: #07b7f7;
    background-color: none;
    cursor: pointer;
  }

.navbar-links:hover {
    color: #07b7f7;
}

.navbar-btn {
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: rgb(5, 140, 224);
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}

.navbar-btn:hover {
    color: #07b7f7;
    background-color: none;

}

.mobile-navbar {
    display: none;
}

@media screen and (max-width: 900px) {
    .navbar-btn, .navbar-items {
        display: none;
    }
}

@media screen and (max-width: 900px) {
.mobile-navbar {
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    width: 50%;
    height: 50vh;  
    position: fixed;
    margin-left: 450px;
    top: 0px;
    left: 100%;
    background-image: linear-gradient(132deg, #dfe6e8 0%, rgb(5, 140, 224) 100%);
    z-index: 20;
    justify-content: center;
    align-items: center;
    transition: left .3s ease-in-out;
    padding-right: 40px;
    border-bottom: 3px solid #1d83c4;
    border-left: none;
    border-right: none;
    border-top: none;
}
.mobile-navbar-close {
    position: absolute;
    top: 15px;
    right: 70px;
}
.mobile-navbar-close .hamb-icon:hover {
    color: rgb(255, 61, 61);
}
.open-nav {
    left: 0;
    margin-top: 0px;
}
.mobile-navbar-links {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    gap: 24px;
    font-family: 'Comfortaa', sans-serif;
    text-align: center;
    text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
}
.mobile-navbar-links li a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-family: cursive;
    font-weight: 900;
    font-family: 'Comfortaa', sans-serif;
    letter-spacing: .9px;
    transition: color .2s ease-in-out;
}

.mobile-navbar-links a.active {
    color: #005d98;
    background: none;
    cursor: pointer;
  }

.mobile-navbar-links li a:hover {
    color: rgb(3, 112, 180);
}
.hamb-icon {
    width: 28px;
    height: 28px;
    color: #fbfbfb;
    cursor: pointer;
}
.hamb-icon:hover {
    color: rgb(5, 140, 224);
}
}

@media screen and (max-width: 768px) {
    .mobile-navbar.open-nav {
        border: none;
    }
}

@media screen and (min-width: 900px) {
    .mobile-nav {
        display: none;
    }
    .hamb-icon:hover {
        color: rgb(5, 140, 224);

    }

    }

    @media screen and (max-width: 768px) {
        .mobile-navbar {
            display: flex;
            flex-direction: column;
            width: 70%;
            height: 50vh;    
            position: fixed;
            margin-left: 250px;
            top: 0px;
            left: 100%;
            background-image: linear-gradient(132deg, #dfe6e8 0%, rgb(5, 140, 224) 100%);
            z-index: 20;
            justify-content: center;
            align-items: center;
            transition: left .3s ease-in-out;
            padding-right: 40px;
            margin-top: 50px;
            border-bottom: 3px solid #1d83c4;
            border-left: none;
            border-right: none;
            border-top: none;
        }
        .mobile-navbar-close {
            position: absolute;
            top: 15px;
            right: 95px;

        }
        .mobile-navbar-close .hamb-icon:hover {
            color: rgb(255, 61, 61);
        }
        .open-nav {
            left: 0;
            margin-top: 0px;
        }
        .mobile-navbar-links {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            font-size: 24px;
            gap: 24px;
            font-family: 'Comfortaa', sans-serif;
            text-align: center;
            text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
        }
        .mobile-navbar-links li a {
            text-decoration: none;
            color: rgb(255, 255, 255);
            font-family: cursive;
            font-weight: 900;
            font-family: 'Comfortaa', sans-serif;
            letter-spacing: .9px;
            transition: color .2s ease-in-out;
        }
        .mobile-navbar-links a.active {
            color: #005d98;
            background: none;
            cursor: pointer;
          }

        .mobile-navbar-links li a:hover {
            color: rgb(3, 112, 180);
        }
        .hamb-icon {
            width: 28px;
            height: 28px;
            color: #fbfbfb;
            cursor: pointer;
        }
        .hamb-icon:hover {
            color: rgb(5, 140, 224);
        }
    }

    @media screen and (max-width: 550px) {
        .mobile-navbar {
            display: flex;
            flex-direction: column;
            width: 70%;
            height: 50vh;    
            position: fixed;
            margin-left: 170px;
            top: 0px;
            left: 100%;
            background-image: linear-gradient(132deg, #dfe6e8 0%, rgb(5, 140, 224) 100%);
            z-index: 20;
            justify-content: center;
            align-items: center;
            transition: left .3s ease-in-out;
            border-bottom: 3px solid #1d83c4;
            border-left: none;
            border-right: none;
            border-top: none;
        }
        .mobile-navbar-close {
            position: absolute;
            top: 15px;
            right: 40px;
        }
        .mobile-navbar-close .hamb-icon:hover {
            color: rgb(255, 61, 61);
        }
        .open-nav {
            left: 0;
            margin-top: 0px;
        }
        .mobile-navbar-links {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            font-size: 24px;
            gap: 24px;
            font-family: 'Comfortaa', sans-serif;
            text-align: center;
            text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
        }

        .mobile-navbar-links a.active {
            color: #005d98;
            background: none;
            cursor: pointer;
          }

        .mobile-navbar-links li a {
            text-decoration: none;
            color: rgb(255, 255, 255);
            font-family: cursive;
            font-weight: 900;
            font-family: 'Comfortaa', sans-serif;
            letter-spacing: .9px;
            transition: color .2s ease-in-out;
        }

        .mobile-navbar-links li a:hover {
            color: rgb(3, 112, 180);
        }


        .hamb-icon {
            width: 28px;
            height: 28px;
            color: #fbfbfb;
            cursor: pointer;
        }
        .hamb-icon:hover {
            color: rgb(5, 140, 224);
        }
        }

        @media screen and (max-width: 470px) {
            .mobile-navbar {
                display: flex;
                flex-direction: column;
                width: 70%;
                height: 50vh;    
                position: fixed;
                margin-left: 140px;
                top: 0px;
                left: 100%;
                background-image: linear-gradient(132deg, #dfe6e8 0%, rgb(5, 140, 224) 100%);
                z-index: 20;
                justify-content: center;
                align-items: center;
                transition: left .3s ease-in-out;
                padding-right: 40px;
                border-bottom: 3px solid #1d83c4;
                border-left: none;
                border-right: none;
                border-top: none;
            }
            .mobile-navbar-close {
                position: absolute;
                top: 15px;
                right: 40px;
            }
            .mobile-navbar-close .hamb-icon:hover {
                color: rgb(255, 61, 61);
            }
            .open-nav {
                left: 0;
                margin-top: 0px;
            }
            .mobile-navbar-links {
                list-style-type: none;
                display: flex;
                flex-direction: column;
                font-size: 24px;
                gap: 24px;
                font-family: 'Comfortaa', sans-serif;
                text-align: center;
                text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
            }

            .mobile-navbar-links a.active {
                color: #005d98;
                background: none;
                cursor: pointer;
              }


            .mobile-navbar-links li a {
                text-decoration: none;
                color: rgb(255, 255, 255);
                font-family: cursive;
                font-weight: 900;
                font-family: 'Comfortaa', sans-serif;
                letter-spacing: .9px;
                transition: color .2s ease-in-out;
            }

            .mobile-navbar-links li a:hover {
                color: rgb(3, 112, 180);
            }
            .hamb-icon {
                width: 28px;
                height: 28px;
                color: #fbfbfb;
                cursor: pointer;
            }
            .hamb-icon:hover {
                color: rgb(5, 140, 224);
            }
            }
