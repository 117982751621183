.Apropos-section {
    padding: 0px 12px;
    margin-left: 10px;
    text-align: center;
    background-color: white;
}

.Apropos-title {
    font-family: 'Rancho', sans-serif;
    font-size: 40px;
    font-weight: 800;
    margin-top: 15px;
    margin-bottom: 2px;
    text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
}

.Apropos-title > span {
    position: relative;
    font-weight: 800;
    text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
}

.Apropos-title span::before {
    content: '';
    position: absolute;
    width: 95%;
    height: 3px;
    background-color: rgb(5, 140, 224);
    border-radius: 2px;
    bottom: -2px;
    font-weight: 800;
    text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
}

.Apropos-description {
    margin: 25px;
    text-align: center;
    color: rgb(77, 75, 75);
    font-family: 'Madimi One', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.5rem;
    font-weight: 800;
    margin-bottom: 10px;
    text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
}

.text-stats {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    font-weight: 800;
    text-shadow: 1px 1px 1px rgb(6, 6, 6), 0 0 1em white, 0 0 0.1em black;
}

.text-stats-container {
    align-items: center;
    padding: 5px;
    margin-bottom: -10px;
}

.text-stats-container > p {
    color: rgb(77, 75, 75);
    font-family: 'Rancho', sans-serif;
    font-size: 31px;
    letter-spacing: .7px;
    font-weight: 800;
    text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
}
.text-stats-container p:first-child {
    margin-bottom: -5px;
    color: rgb(5, 140, 224);
    font-family: 'Rancho', sans-serif;
    font-size: 40px;
    font-weight: 800;
    text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
    margin-right: 10px;
}

img, svg {
    vertical-align: middle;
    size: 59px;
    width: 77px;
    height: 64px;
    font-weight: 800;
    text-shadow: 1px 1px 1px rgb(4, 2, 2), 0 0 1em rgb(0, 0, 0), 0 0 0.1em black;
    background-color: transparent; 
}

.Apropos-cards {
    height: auto;
    border: 3px solid #d7d3d3;
    border-radius: 22px;
    background-color: white;
    position: relative;
    padding: 0px 0px 0px 0px;
    margin-right: 10px;
    margin-top: 40px;
    border: 5px solid rgb(5, 140, 224);
}

.Apropos-cards-content {
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 22px;
    font-weight: 800;
    text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
}

.Apropos-card-title {
    margin: 59px 10px 10px 10px;
    line-height: 45px;
    color: rgb(5, 140, 224);
    font-family: 'Rancho', sans-serif;
    font-size: 42px;
    font-weight:bold;
    letter-spacing: .8px;
    font-weight: 800;
    text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
}

.Apropos-card-button{
    padding: 12px 15px;
    margin-bottom: 22px;
    margin-right: 7px;
    color: rgb(255, 255, 255);
    border: 3px solid white;
    border-radius: 28px;
    outline: transparent;
    background-color: rgb(5, 140, 224);
    font-size: 22px;
    font-family: 'Madimi One', sans-serif;
    letter-spacing: .8px;
    color: white;
    cursor: pointer;
    font-weight: 800;
    transition: all .4s ease;
    text-shadow: 1px 1px 1px rgb(29, 28, 28), 0 0 1em white, 0 0 0.1em black;
    box-shadow: 5px 5px 9px 1px rgba(1, 9, 20, .4);
}

.Apropos-card-button:hover {
    padding: 12px 15px;
    background: #f7f7f7;
    color: #058ce0;
    border: 3px solid #058ce0;
    text-shadow: 1px 1px 1px rgb(255, 255, 255), 0 0 1em rgb(242, 240, 240), 0 0 0.1em rgb(251, 251, 251);
  }

.Apropos-card-description {
    margin: 15px;
    color: rgba(75, 77, 75, 0.763);
    font-family: 'Madimi One', sans-serif;
    font-size: 18px;
    letter-spacing: .5px;
    line-height: 1.4rem;
    font-weight: 800;
    text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
}

.Apropos-card-gif {
    position: absolute;
    text-align: center;
    vertical-align: middle;
    display: flex;
    top: -46px;
    left: 42%;
    padding: 14px;
    border-radius: 30px;
    margin-left: -21px;
    background-color: white;
    border: 3px solid rgb(5, 140, 224);
}

.Apropos-card-gif.twenty {
  width: 300px; 
  height: auto;
}

.Apropos-card-gif.fifty {
  width: 300px; 
  height: auto; 
}

.Apropos-image2 {
    width: 59%;
    height: auto;
    max-width: 100%;
    text-align: center;
    border-radius: 40px;
    margin-bottom: 15px;
    margin-top: 30px;
    border: 3px solid rgb(5, 140, 224);
    box-shadow: 7px 5px 5px 1px rgba(1, 1, 1, 0.4);
}

.partner-text {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #14a7e6;
  margin-top: 1px;
  margin-bottom: -12px;
  font-family: Rancho, sans-serif;
  text-shadow: 1px 1px 1px #46494e, 0 0 .1em #8f949a, .1em 0 .1em #4b4f56;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;         
  align-items: center;   
  gap: 0px; 
  justify-content: center;
}

.partner-text p {
    margin-left: -16px;
}

.logo-capvital-container {
  display: flex;
  justify-content: center; 
  align-items: center;    
  margin-top: 20px;       
}

.logo-capvital {
  width: auto;         
  height: 59px;          
}

.logo-bms {
  max-width: 99px;
  height: auto;
}

@media screen and (max-width: 1000px) {
    .Apropos-image2 {
        display:inline-block;
        width: 90%;
        height: auto;
        border-radius: 35px;
        margin-bottom: 15px;

    }
    .Apropos-description {
        margin: 12px 8px 35px 8px;
    }
    .info-cards-content {
        grid-template-columns: repeat(2, 1fr);
    } 

    .text-stats {
        margin-top: -22px;
        display: grid;
        justify-content: center;
        align-items: center;
        gap: 2px;
        font-weight: 800;
        text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
    }
    .text-stats-container {
       display: center;
       padding: 5px;
    }
}
@media screen and (max-width: 800px) {
    .Apropos-image2 {
        display:inline-block;
        width: 100%;
        height: auto;
        border-radius: 35px;
        margin-left: -10px;
        margin-bottom: 15px;

    }
    .Apropos-description {
        margin: 12px 8px 35px 8px;
    }
    .Apropos-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }

    .text-stats {
        margin-top: -22px;
        display: grid;
        justify-content: center;
        align-items: center;
        gap: 2px;
        font-weight: 800;
        text-shadow: 1px 1px 1px white, 0 0 1em white, 0 0 0.1em black;
    }
    .text-stats-container {
        align-items: center;
        padding: 5px;
    }
}

@media screen and (max-width: 700px) {
    .Apropos-image2 {
        display:inline-block;
        width: 100%;
        height: auto;
        border-radius: 35px;
        margin-bottom: 15px;

    }
    .Apropos-cards {
        grid-template-columns: repeat(1, 1fr);
    }
    .Apropos-description {
        margin: 12px 8px 35px 8px;
    }
    .Apropos-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }
}
